import React, { MutableRefObject, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import style from '../styles/dropDown.module.css'
import LanguageContext from '../context/languageContext'
import useResizeObserver from "use-resize-observer";

const DropDown = () => {
	const [open, setOpen] = useState(false)
	const { activeLang, setActiveLang } = useContext(LanguageContext)
	const [languages, setLanguages] = useState([
		{ title: "English", value: "en", checked: true },
		{ title: "French", value: "fr", checked: false },
		{ title: "Russian", value: "ru", checked: false },
		{ title: "Chinese", value: "ch", checked: false },
		{ title: "Spanish", value: "sp", checked: false },
		{ title: "Hindi", value: "hi", checked: false },
	])

	const container = useRef() as MutableRefObject<HTMLDivElement>
	const itemsContainer = useRef() as MutableRefObject<HTMLDivElement>

	const { ref } = useResizeObserver<HTMLDivElement>({
		onResize(size) {
			container.current?.style.setProperty("--h", `${size.height}px`);
		}
	});

	useLayoutEffect(() => {
		if (activeLang) {
			categoriesHandler(activeLang)
		}
	}, [activeLang])

	const categoriesHandler = (value: string) => {
		const newLanguages = [...languages]
		const temp = newLanguages.find(language => language.checked)
		if (temp) {
			temp.checked = false
		}
		const language = newLanguages.find(language => language.value === value)
		if (language) {
			language.checked = true
		}
		setLanguages(newLanguages)
		setOpen(false)
	}

	const onChangeLanguage = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, language: string) => {
		e.preventDefault()
		setActiveLang(language)
		localStorage.setItem('lang', language)
	}

	useEffect(() => {
		itemsContainer.current.addEventListener("animationstart", (e: AnimationEvent) => {
			if (e.animationName === style.open && e.target === itemsContainer.current)
				itemsContainer.current.setAttribute("closing", "")
		}, { once: true })
	}, [])

	return (
		<div ref={container} className={style.container} onClick={() => setOpen(prev => !prev)}>
			{languages.find(item => item.checked)?.title}
			<img src="/image/dropDownArrow.svg" alt="" />

			<div {...{ open }} ref={itemsContainer} className={style.items}>
				<div ref={ref} className="flex flex-col">
					<div>
						{languages.map(language => {
							return (
								<div className={style.item} key={language.value} onClick={(e) => onChangeLanguage(e, language.value)}>
									{language.title}

									{language.checked && (
										<img src="/image/dropDownCheck.svg" alt="" />
									)}
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</div>
	)
}

export default DropDown
