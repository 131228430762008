import {ILocale} from "./type"

const en: ILocale = {
	header: {
		blog: "Блог",
		enterWokOS: "WOK-OS Вход",
		enterNFT: "NFT Shop",
		features: "Особенности",
		franshise: "Франшиза",
		vision: "Наше видение",
		nft: "NFT",
		staking: "Стейкинг",
		roadmap: "Roadmap",
		token: "Токен",
		bookOfKnowlage: "Книги",
	},
	playSection: {
		openWorld: "ai gamaing gamefi persistant non-linear метавселенная открытый мир",
		sound: "Звук: ",
		on: "вкл.",
		off: "выкл."
	},
	subscribeSection: {
		dontMiss: "Don\'t miss the boat",
		weAreGoing: "Мы выйдем на рынок скоро",
		beTheFirst: "Не теряйте возможность, будьте первыми в курсе событий",
		subscribe: "Подписаться",
		success: "Ваши данные успешно отправлены! Ждём вас на острове!",
		networkError: "Ошибка сети",
		emailError: "Неверный email",
		placeholder: "Email адрес",
		joinToFreeNft: "Subscribe today and",
		groupName: "join our discord",
		claimYourNft: "to claim your",
		freeNft: "FREE",
		claimNft: "NFT Wolf reward. Escape reality, join the tribe",
		title:"AI Gaming",
		definition1: "Gaming AI is a revolutionary way to use AI to create non-linear gameplay. This new approach will enable metaverses and persistent virtual worlds to entertain their participants without limitations.", definition2: "While normal games, even open-world RPGs, are linear and require players to complete certain tasks in a specific order to reach the end, the Gaming AI approach senses the player\'s abilities and adapts the difficulty, events, and gaming experience to their social status, rank, weapons, and abilities. ",
		definition3: "This creates a never-ending game that can be played in any order with total freedom of movement. This new approach to gaming will allow metaverses to offer high-quality gamification in a persistent world."	
	},
	aigamingSection: {
		title:"AI Gaming",
		definition1: "Gaming AI is a revolutionary way to use AI to create non-linear gameplay. This new approach will enable metaverses and persistent virtual worlds to entertain their participants without limitations.", definition2: "While normal games, even open-world RPGs, are linear and require players to complete certain tasks in a specific order to reach the end, the Gaming AI approach senses the player\'s abilities and adapts the difficulty, events, and gaming experience to their social status, rank, weapons, and abilities. ",
		definition3: "This creates a never-ending game that can be played in any order with total freedom of movement. This new approach to gaming will allow metaverses to offer high-quality gamification in a persistent world."
	},
  sliderSection: {
    firstSlideTitle: "ЗАЩИТИ ОСТРОВ,\nИ СВОИХ ДРУЗЕЙ\nОт Мутантов",
    firstSlideText: "Сходи и проверь все ли в порядке, работает ли электричество, которое питает защиту против плохих ребят",
    firstStory: "Мутанты",
    secondStory: "Открытый мир",
    thirdStory: "Играй и зарабатывай",
  },
	mapSection: {
		title: "Множество локаций для исследований",
		firstAdvantageTitle: "8 000 000 m²",
		firstAdvantageText: "Площадь острова",
		secondAdvantageTitle: "1 000 людей",
		secondAdvantageText: "Могут одновременно играть на острове",
		thirdAdvantageTitle: "> 100 000",
		thirdAdvantageText: "Деревьев, камней, людей и множество мест которые можно посетить",
	},
	charactersSection: {
		title: "Кастомизируй своего персонажа с помощью NFT",
		text: "Metaisland это платформа социальной сети, с виртуальными аватарами, чатами, дружбами, статусами и лайками. Вы можете выбрать вашего персонажа из +150 уникальных 3d NFT.",
	},
	learnTheStorySection: {
		title: "Узнай историю",
		firstStory: "История",
		secondStory: "Арендуй магазин",
		thirdStory: "Борись с мутантами",
		fourthStory: "Исследуй океан",
	},
	nftSection: {
		title: "Уникальные 2D NFT",
		button: "Перейти в NFT магазин",
		aquamen: "Aquamen",
		military: "Military",
		punks: "Punks",
		redcards: "Redcards",
		tribes: "Tribes",
		builders: "Builders",
		wolfWood: "Wolf Wood",
		wolfIron: "Wolf Iron",
		daemons: "Daemons"
	},
	playToEarnSection: {
		title: "Играй и зарабатывай",
		subtitle: "Metaisland это платформа социальной сети, с виртуальными аватарами, чатами, дружбами, статусами и лайками. Вы можете выбрать своему персонажу скин и создать свой собственную личность с RPG характеристиками.",
		earn: "Зарабатывай",
		earnFirstTitle: "Находи криптовалюту",
		earnFirstText: "Аирдропы и добыча камней",
		earnSecondTitle: "выполняй квесты",
		earnSecondText: "Получай квесты от NPC",
		earnThirdTitle: "Арендуй бизнес",
		earnThirdText: "Рестораны и обменники валют - доступны в аренду",
		spend: "Трать",
		spendFirstTitle: "NFT",
		spendFirstText: "Аудио, Картины, Скины, Объекты и Способности",
		spendSecondTitle: "Покупай еду",
		spendSecondText: "Вам нужна чтобы управлять своими ресурсами",
		spendThirdTitle: "Пей алкоголь",
		spendThirdText: "Пиво так же поможет зарядить энергию",
	},
	soonSection: {
		title: "Скоро",
		subtitle: "Оригинальная Франшиза Метавселенной",
		soonFirstTitle: "Продвинутый Игровой Движок",
		soonFirstText: "MetaIsland обрабатывает в режиме реального времени интригующее участие в кооперативном 3D открытом мире, доступно благодаря Unreal Engine 5",
		soonSecondTitle: "Максимум Компонуемости",
		soonSecondText: "Зарабатывай в DeFi, играй в игры, общайся, участвуй в DAO и испытай Web3 экономику с Holochain для генезиса MetaIsland и его пользователями.",
		soonThirdTitle: "Генезис Остров & Франшиза",
		soonThirdText: "Расширь свой Web3 горизонт вместе с превосходной финтех технологией. Используй готовую возможность партнерства с Генезис Островом или с помощью франчайзинга собственной версии!",
	},
	roadmapSection: {
		title: "Roadmap",
		first: "NFT продажи в нашем маркетплейсе",
		second: "Первая играбельная версия с первым островом",
		third: "Система диалогов, Путешествий, Рассказ историй",
		fourth: "Боевая система. Вертикальная разработка",
		fifth: "Франчайзинг и горизонтальная разработка, развитие бизнеса.",
		button: "Узнать подробнее о Roadmap",
	},
	blogSection: {
		title: "Блог",
		button: "Читать больше постов",
	},
	teamSection: {
		title: "W.O.K Corp Studio",
		subtitle: "W.O.K Corp studio  is +20 talented people spread around the globe",
		nicolas: {name: "Nicolas Choukroun", profession: "CEO", about: "Ubisoft, Mindscape, Fox, Loriciel, Cryo, EA, Euronext"},
		laurent: {name: "Laurent Cluzel", profession: "Graphics Director", about: "Ubisoft, Sony, Psygnosis, Wizama, EA, Namco"},
		ethan: {name: "Ethan Lewis Maltby", profession: "Director Sound and Music", about: "UEFA, FA, NFL, RBS, Microsoft, Nestle, Cineworld, Telenet, BP, Wella, Walls, Leeds Castle, KCC"},
		iliah: {name: "Ilya Cerberex", profession: "Lead Blockchain Specialist", about: "Python, Php, C++, Full Stack, NEO Chain, Solidity, Hosting"},
		pascale: {name: "Pascale Piel", profession: "CFO", about: "Antabio SAS, Theravectys, Sofrepost, Novexel, Axa"},
		pierre: {name: "Pierre Esteve", profession: "Storywriter Musician", about: "Arte, Movies, TV, Starsync"},
		vitalik: {name: "Vitalik Mraksoll", profession: "Blockchain Developer", about: "C++, React, Python, Solidity, Rust"},
		sanny: {name: "Sanni Muhammed", profession: "Head of Community Manager", about: "Twitter, Reddit, Discord"},
		daniel: {name: "Daniel Morar", profession: "Lead Unreal developer", about: "Blueprint, ALS Character specialist"},
		kriss: {name: "Krzysztof Sandomierski", profession: "Project Manager & Tester", about: "20+ years experience managing teams in the petroleum industry."},		
		abhay: {name: "Abhay Prakashchandra Mahajan", profession: "Backend / WOK-OS Api", about: "PHP/Mysql wizard"},		
		stephane: {name: "Stephane Picq", profession: "Game Music Composer", about: "Atlantis, Dune, Megarace, Captain Blood"},
		beesirin: {name: "Beesirin Impaw", profession: "General Manager Thailand", about: ""},
		sandomierski: {name: "Sandomierski Krzysztof", profession: "Project Manager", about: ""},
	},
		advisorySection: {
		title: "Члены совета",
		hassan: {name: "Hassan (Hatu) Sheikh", profession: "Экономика токена, франчайзинг", about: "Соучредитель Daomaker. Founder of ApeTerminal.io"},
		iftikhar: {name: "Iftikhar Qasim", profession: "Senior Advisor SL2 Capital & Hillstone Finance", about: ""},
		serge: {name: "Serge Hascoet", profession: "Chief Creative Officer at Ubisoft for +30 years", about: ""},
		david: {name: "David Atkinson", profession: "Token Economy Advisor", about: "Соучредитель Holochain"},
		andrey: {name: "Andrey He", profession: "Маркетинг & Франчайзинг", about: "NEO chain"},
		babar: {name: "Babar Shabir", profession: "NFT и блокчейн стратегии", about: ""},
		konstantin: {name: "Konstantin Pechesky", profession: "President of Trinity, investment & crypto expert", about: "Trinity - Venture Capital Club"},				
	},
	partnersSection: {
		title: "Партнеры",
		subtitle: "The Team has published games or worked for these following companies",
		investors: "Seed Инвесторы",
		technologyAndLegalPartners: "Технологии и юридические партнеры",
		wizama: "Magical board game builders since 2017",
		nintendo: "Japanese multinational video game company",
		sega: "Japanese multinational video game and entertainment company",
		ea: "American video game company",
		sony: "Japanese multinational conglomerate corporation",
		mindscape: "French video game developer",
		cryo: "French video game development",
		ubisoft: "French video game company",
	},
	popUpMining: {
		title: "Зона аирдропов и Майнинг",
		text: "Каждые 2 часа звучит предупреждающий сигнал и происходит запуск аирдропа. По истечению 4-ех минут, вся криптовалюта которую игроки не собрали - уничтожается",
		more: "Подробнее"
	},
	popUpFactory: {
		title: "Electric Factory",
		text: "В игре полностью смоделирована вся электрическая система. Кабели покрывают все области и есть даже солнечные батареи. Tesla Coil отправляют энергию на Electric Factory, где она обрабатывается, хранится и доставляется на остальную часть острова. Есть также способы включения и выключения электричества в этой области.",
		more: "Подробнее",
	},
	popUpCyber: {
		title: "Cyber area",
		text: "The Cyber area - является одним из самых больших зданий острова. Там могут разместиться около 500 человек. Это дикое место с алкоголем, наркотиками и проститутками.",
		more: "Подробнее",
	},
	popUpSlum: {
		title: "Slum Ville",
		text: "это самое дешевое место, где можно получить комнату, когда вы начинаете игру. Он находится далеко от зоны майнинга/аирдропа, но имеет крутую атмосферу. Хорошо для начала игры.",
		more: "Подробнее",
	},
	popUpTech: {
		title: "Tech Ville",
		text: "Tech Ville - является административным районом. В отеле есть конференц-залы и станция телепортации. Также в районе есть знающие люди, у которых можно получить информацию об острове. В этом районе есть музей, несколько домов, станция телепортации, несколько ресторанов, продуктовый магазин и супермаркет.",
		more: "Подробнее",
	},
	popUpLeaw: {
		title: "Leaw Beach",
		text: "Leaw Beach - это первый пляж для посещения. Мы обновим игру, чтобы их было намного больше. Это место, где можно расслабиться и насладиться ночным клубом или баром.",
		more: "Подробнее",
	},
	popUpTesla: {
		title: "Tesla Coil area",
		text: "Эта область генерируют энергию для всех построек на острове. Энергия поступает из крипто-шахт под землей. На острове разбросано несколько катушек-Теслы, некоторые из них находятся в Дикой зоне и опасны, потому что они защищают себя, уничтожая всех приближающихся людей. Район Tesla Energy расположен недалеко от Techville, и игрокам необходимо следить за тем, чтобы он всегда работал. Есть способы включать и выключать его, и некоторые игроки должны будут получить знания, чтобы избежать любого отключения электроэнергии в инфраструктуре, которое может подвергнуть охраняемую зону риску вторжения существ Дикой зоны.",
		more: "Подробнее",
	},
	popUpTribe: {
		title: "Tribe Village",
		text: "Это самое дешевое место, где можно получить комнату, когда вы начинаете игру. Он находится далеко от зоны майнинга/аирдропа, но имеет крутую атмосферу. Хорошо для начала игры.",
		more: "Подробнее",
	},
	footer: {
		whitepaper: "Whitepaper",
		pitchdeck: "Pitch Deck Detailed",
		pitchdecklite: "Pitch Deck Lite",
		metaisland: "METAISLAND",
	},
	islToken: {
		title: "ISL токен",
		subtitle: "Токен ISL это основанная валюта в экосистеме Metaisland: слой-1, Метавселенная,\nNFT экосистема и нативный GameFi. Экономика\nтокена включает в себя стейкинг, локи, поддержание\nликвидности, выкупы и сжигания. Это обусловлено\nспросом вызванным как игровых привилегий, а так же\nценностью привлеченной из экономики метавселенной.",
		tokenEconomy: "Экономика Токена",
		playIncome: "Игровой доход",
		playIncomeFirstTitle: "Месячная подписка",
		playIncomeFirstText: "В метавселенную можно играть бесплатно, но чтобы разблокировать все возможности, участники должны платить ежемесячную плату в размере минимум 10 долларов.",
		playIncomeSecondTitle: "Мерч",
		playIncomeSecondText: "Metaisland — это франшиза с персонажами и историями, которые позволяют нам создавать все виды мерчандайзинга, как в любых популярных франшизах.",
		playIncomeThirdTitle: "Рисковая игра",
		playIncomeThirdText: "Игроки могут арендовать магазин, чтобы иметь MPR (ежемесячный процентный доход). Процент ставок зависит от местоположения магазина и успеха.",
		nftIncome: "NFT Доход",
		nftIncomeFirstTitle: "Маркетплейс NFT",
		nftIncomeFirstText: "2,5% комиссии применяется ко всем сделкам NFT на торговой площадке NFT и в приложении.",
		nftIncomeSecondTitle: "Продажи NFT",
		nftIncomeSecondText: "Наши NFT открывают функции в Метавселенной, игрокам придется собрать многие из них, чтобы получить доступ ко всем этим функциям.",
		nftIncomeThirdTitle: "Аренда NFT",
		nftIncomeThirdText: "Игрок может взять в аренду наши NFT, чтобы заработать деньги, а также сдать в аренду свои NFT, чтобы заработать дополнительные деньги.",
	},
	tokenEconomy: {
		firstTitle: "Ценность ISL токена из игровой экономики",
		gameEconomyFirstTitle: "Выкуп",
		gameEconomyFirstText: "50% от игрового дохода используется для выкупа токенов по рынку",
		gameEconomySecondTitle: "Сжигание",
		gameEconomySecondText: "DAO может проголосовать за сжигание до 50% токенов которые были выкуплены",
		gameEconomyThirdTitle: "Награды за стейкинг",
		gameEconomyThirdText: "До 50% токенов, выкупленных с рынка, распределяются между стейкерами",
		secondTitle: "Ценность ISL Токена\n в экосистеме",
		ecosystemFistTitle: "Стейкинг",
		ecosystemFistText: "Стейкинг токенов и токенов LP открывает определенные скины WOK и Avatar. Аватары видны в таблице лидеров, друзьям и на общедоступной карте.",
		ecosystemSecondTitle: "Скидки",
		ecosystemSecondText: "2.5% комиссия роялти взимается за торги игровых NFT, т.е. скины. Эту комиссию можно снизить до 2% если игрок стейкает минимальную сумму токенов",
		ecosystemThirdTitle: "Выкуп",
		ecosystemThirdText: "50% комиссий от торгов NFT идут на выкуп токена по рынку, в свою очередь DAO может проголосовать за их сжигание",
		ecosystemFourthTitle: "Доходность стейкинга",
		ecosystemFourthText: "До 50% комиссий от торгов NFT доступны как награда за стейкинг",
	},
	tokenDistribution: {
		title: "Распределение Токена",
		secondTitle: "Всего: 100% — 1,000,000,000",
	},
	visionMetaisland: {
		title: "Что такое \nMetaisland?",
		subtitle: "Инфраструктура метавселенной для миллиарда пользователей",
		text: "Metaisland это инфраструктура блокчейна, созданная для Метавселенной. Совместима с большинством популярных кошельков, связана со всеми основными блокчейнами и сочетает в себе defi, NFT и модель play 2 earn в уникальной революционной концепции.",
	},
	metaverseBlock: {
		firstTitle: "Экономика метавселенной будет равна $13 триллионов к 2030",
		secondTitle: "Метавселенная для всех",
		subtitle: "Компания c Wall Street - Morgan Stanley рассматривает метавселенную как рынок стоимостью 8 триллионов долларов, который, вероятно, станет «социальной медиа, стриминг и игровой платформой следующего поколения».\n" +
			"Городские власти настроены еще более оптимистично и заявили: «Мы считаем, что Метавселенная — это следующее поколение Интернета, сочетающее физический и цифровой мир в постоянном и иммерсивном режиме, а не просто мир виртуальной реальности», — говорится в отчете. “Независимая от устройств Метавселенная, доступная через ПК, игровые приставки и смартфоны, может привести к созданию очень большой экосистемы.”",
		date: "(Fortune April 2022)",
		FirstTitle: "Metaisland Полезность токена ISL",
		FirstText: "Токен ISL — это топливо, которое расширяет возможности Metaisland. Владельцам островов необходимо купить токен ISL на рынке, чтобы пополнить свой резервуар вознаграждения.",
		SecondTitle: "2D NFT Multichain",
		SecondText: "Доступно 9 коллекций 2d PFP NFT, каждая из которых имеет полезность. Они будут доступны в multi-chain и на множестве различных торговых площадок.",
		ThirdTitle: "3D NFT с функциями RPG",
		ThirdText: "В наши 3D NFT доступны прямо в игре и они имеют такие функции RPG, такие как сила, скорость, жизнь, мана, голод, жажда и т. д.",
		FourthTitle: "Магазин NFT, купить или арендовать",
		FourthText: "Наши NFT можно приобрести за наличные, криптовалюту или даже арендовать! Не нужно быть богатым, чтобы наслаждаться Metaisland.",
		FifthTitle: "WOK-OS для управления вашим ID",
		FifthText: "WOK-OS — это наш сервер для управления идентификацией пользователей, он гарантирует конфиденциальность каждого участника метавселенной.",
		SixthTitle: "Metaisland Франшиза",
		SixthText: "Лучшие пользователи метавселенной получают доступ к управлению собственным островом и зарабатывают на жизнь, управляя собственным Metaisland.",
		firstAdvantageTitle: "Подписки за наличные",
		firstAdvantageText: "Один остров с 1000 игроков приносит 20 000 долларов США в месяц с продаж.",
		secondAdvantageTitle: "Подписки за криптовалюту",
		secondAdvantageText: "1 миллион игроков будет генерировать миллионы USDT продаж в месяц.",
		thirdAdvantageTitle: "NFT продажи",
		thirdAdvantageText: "Хотя NFT не являются обязательными для игры, их уникальные атрибуты делают их очень привлекательными.",
		fourthAdvantageTitle: "NFT гонорары",
		fourthAdvantageText: "10% с каждой продажей может генерировать большой поток доходов со временем",
		fifthAdvantageTitle: "Франчайзинг",
		fifthAdvantageText: "Франчайзинг позволяет нам стать поставщиком контента и продавать ключи API без необходимости локализации в каждой стране.",
		sixthAdvantageTitle: "Мерч",
		sixthAdvantageText: "Майки, кружки, а так же другие вещи связанные с игрой доступны на продажу в онлайн магазине",
	},
	visionSlider: {
		title: "Metaisland Привносит метавселенную каждому",
		subtitle: "Metaisland — это платформа социальной сети с виртуальным аватаром, чатами, дружбой, статусом и лайками. Вы можете выбрать персонажа из более чем 150 уникальных 3D-персонажей и создать свою собственную личность, используя характеристики, подобные RPG.",
	},
	booksPlay: {
		title: "Metaisland",
		text: "ИМЕЕТ УНИКАЛЬНУЮ серию из книг",
	},
	books: {
		title: "выбери свою книгу",
		firstTitle: "Red Cards",
		firstDescription: "Описание",
		firstText: "От Nicolas Choukroun, Nick Brown",
		secondTitle: "Tribes",
		secondDescription: "Описание",
		secondText: "От Nicolas Choukroun, Nick Brown",
		thirdTitle: "Aquamen",
		thirdDescription: "Описание",
		thirdText: "От Nicolas Choukroun, Nick Brown",
		fourthTitle: "Military",
		fourthDescription: "Описание",
		fourthText: "От Nicolas Choukroun, Nick Brown",
		fifthTitle: "Punks",
		fifthDescription: "Описание",
		fifthText: "От Nicolas Choukroun, Nick Brown",
		sixthTitle: "Daemons",
		sixthDescription: "Описание",
		sixthText: "От Nicolas Choukroun, Nick Brown",
		seventhTitle: "Builders",
		seventhDescription: "Описание",
		seventhText: "От Nicolas Choukroun, Nick Brown",
		eightsTitle: "Wolf wood",
		eighthDescription: "Описание",
		eightsText: "От Nicolas Choukroun, Nick Brown",
		ninthTitle: "Wolf iron",
		ninthDescription: "Описание",
		ninthText: "От Nicolas Choukroun, Nick Brown",
	},
	stakingSlider: {
		title: "Стейкинг это фактически аренда магазина",
		subtitle: "Вы можете владеть или арендовать наши 3D NFT. Каждая серия имеет свои особенности, вдохновленные ролевыми играми (RPG). Они доступны и в них можно играть прямо в игре",
		secondTitle: "Нажмите, чтобы арендовать собственный магазин",
		button: "Перейти к аренде",
	},
	stakingMechanism: {
		title: "Metaisland",
		subtitle: "ИМЕЕТ УНИКАЛЬНЫЙ МЕХАНИЗМ СТЕЙКИНГА",
		button: "Перейти к Стейкингу",
		firstStep: "АРЕНДА МАГАЗИНА В METAISLAND",
		secondStep: "КАЖДЫЙ МАГАЗИН ИМЕЕТ СВОЮ MPR (ежемесячную процентную ставку)",
		thirdStep: "КАЖДЫЙ МЕСЯЦ ПОЛУЧАЙ % MPR ОТ СТОИМОСТИ АРЕНДЫ",
	},
	nftMechanism: {
		title: "Metaisland",
		subtitle: "ИМЕЕТ УНИКАЛЬНЫЕ NFT особенности",
		button: "Перейти в NFT магазин",
		firstStep: "Перейти в NFT магазин",
		secondStep: "Вы можете владеть или арендовать NFT, платить наличными или кошельком",
		thirdStep: "Теперь вы можете использовать свои NFT внутри Метавселенной.",
	},
		pageNotFound: {
		title: "404 - Страница не найдена",
		subtitle: "Вы что-то пропустили?",
		button: "Вернуться на главную страницу",
	},
	sinceTimer: {
		title: "We are building Metaisland for",
				subtitle: "Follow us and join our community!",
		second: "second",
		minute: "minute",
		hour: "hour",
		day: "day",
		month: "month",
		year: "year",
		seconds: "seconds",
		minutes: "minutes",
		hours: "hours",
		days: "days",
		months: "months",
		years: "years",
	},
	subscribeEmail: {
		title: "Survivor, you did it!",
		alreadySub: "Already joined!",
		subtitle: "Welcome to Metaisland! \nYou have taken the first step in escaping the brutal reality and boredom of reality!\n At Metaisland, we are creating a future virtual reality, with an economy a social system and plenty of ways to entertain yourself in a toxic free environment. A decentralized place where freedom of speech is respected and where you can enjoy meeting friends and have create a different personality, do things extraordinary and live in an intriguing Island full of mystery. \nYou can enjoy a beer and relax, talk with friends, or go after the Mutants in the Wild Zone and have a lot of action, fights and explosions. You can also monetize the time spent in the game with all kind of DEFI possibilities that no other entertainment product is proposing. \nMetaverses are the FUTURE! And no need to wait long time to participate, you can join today this revolution. \nAs a token of appreciation for joining us on our journey of building Metaisland, we are delighted to offer you an opportunity to win a free NFT \nThis NFT is a FOUNDER NFT and will have the unique possibility to enter any Metaisland without asking for citizenship, as a VIP member of the project. Don\’t miss this opportunity!",
		toRedeem: "To redeem this Free NFT, follow these steps.",
		discord: "Discord",
		joinDiscord: "Join our Discord server:",
		crew: "Crew3",
		joinCrew: "Go to our Crew 3 page and complete the tasks:",
		bottomText: "Check out our weekly podcast series, Meta Talks, for all the latest info on Metaverse. Nikko Founder and CEO from Metaisland has got you covered, so be sure to join in and learn more about Crypto, GameFi, and the Metaverse itself. \nLet\’s build Metalsland together!",
		talkWith: "Meta Talk With Nikko CEO MetaIsland - WIN FREE NFT - YouTube",
		button: "Go to the Main Page",
	},
	box: {
		title: "Нажимай для генерации NFT MAGIC BOX!",
		button: "Генерируй",
		nftTitle: "Эксклюзивное предложение для ранних пользователей",
		nftSubtitle: "Вы получите 3 NFT по цене одного! Всего лишь по цене 0,1 ETH",
		firstNftTitle: "Daemons#41",
		firstNftText: "NFT 2D Аватары",
		secondNftTitle: "Charlie#08",
		secondNftText: "NFT 2D Аватары",
		thirdNftTitle: "Wolf wood#175",
		thirdNftText: "NFT 2D Аватары",
	},
	browseNft: {
		title: "METAISLAND 3D NFT",
		subtitle: "Вы можете владеть или арендовать 3D NFT. Каждая серия имеет свои особенности, вдохновленные ролевыми играми (RPG). Они выглядят именно так и вы можете ими играть",
		secondTitle: "Нажми для ПРОСМОТРА NFT",
		button: "Просматривать",
		firstNftTitle: "John#04",
		firstNftText: "NFT 3D Аватары",
		secondNftTitle: "Ariana#05",
		secondNftText: "NFT 3D Аватары",
		thirdNftTitle: "Assassin#01",
		thirdNftText: "NFT 3D Аватары",
	},
	mysterySlider: {
		title: "METAISLAND 2D NFT",
		subtitle: "Все наши Profile 2D NFT имеют определенные особенности, такие как способность плавать или невосприимчивость к яду. У нас есть 7 фракций, каждая из которых имеет свою историю и полезность в игре. У игроков может быть портфолио NFT, которое они могут использовать в Метавселенной, чтобы добавить больше возможностей своему 3D-персонажу.",
		secondTitle: "Нажми чтобы узнать больше об фракциях",
		button: "Книга знаний",
		secondButton: "Просмотр NFT WOLF PASSES",
		wolfTitle: "METAISLAND WOLF PASS",
		wolfText: "Наши Wolf Passes используются для получения доступа в скрытые места на Острове. Так же они используются для права голосования в нашем DAO и доступ к приватным Beta версиям. А так же они круты и креативны, они хороши для использования в качестве картинки профиля в ваших любимых соц-сетях. Все наши NFT имеют 2 версии: чистую без текста и расширенная версия с текстом.",
		nftTitle: "WOLF PASS",
		nftText: "Metaisland",
	},
}

export default en
