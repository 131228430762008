import {ILocale} from "./type"

const fr: ILocale = {
	header: {
		blog: "Blog",
		enterWokOS: "Entrer WOK-OS",
		enterNFT: "NFT Shop",
		features: "Features",
		franshise: "Franchise",
		vision: "Vision",
		nft: "NFT",
		staking: "Staking",
		roadmap: "Roadmap",
		token: "Token",
		bookOfKnowlage: "Livres",
	},
	playSection: {
		openWorld: "IA Gaming monde ouvert persisrtant non-lineaire metaverse gamefi",
		sound: "Son: ",
		on: "on",
		off: "off"
	},
	subscribeSection: {
		dontMiss: "Don\'t miss the boat",
		weAreGoing: "Nous allons bientôt commercialiser",
		beTheFirst: "Soyez le premier informé et ne manquez pas l\'occasion",
		subscribe: "S\'abonner",
		success: "Vos données ont été soumises ! Rendez-vous sur l\'île !",
		networkError: "Erreur réseau",
		emailError: "Adresse Email incorrecte",
		placeholder: "Adresse e-mail",
		joinToFreeNft: "Subscribe today and",
		groupName: "join our discord",
		claimYourNft: "to claim your",
		freeNft: "FREE",
		claimNft: "NFT Wolf reward. Escape reality, join a tribue",
		title:"AI Gaming",
		definition1: "Gaming AI is a revolutionary way to use AI to create non-linear gameplay. This new approach will enable metaverses and persistent virtual worlds to entertain their participants without limitations.", definition2: "While normal games, even open-world RPGs, are linear and require players to complete certain tasks in a specific order to reach the end, the Gaming AI approach senses the player\'s abilities and adapts the difficulty, events, and gaming experience to their social status, rank, weapons, and abilities. ",
		definition3: "This creates a never-ending game that can be played in any order with total freedom of movement. This new approach to gaming will allow metaverses to offer high-quality gamification in a persistent world."		
	},
	aigamingSection: {
		title:"AI Gaming",
		definition1: "Gaming AI is a revolutionary way to use AI to create non-linear gameplay. This new approach will enable metaverses and persistent virtual worlds to entertain their participants without limitations.", definition2: "While normal games, even open-world RPGs, are linear and require players to complete certain tasks in a specific order to reach the end, the Gaming AI approach senses the player\'s abilities and adapts the difficulty, events, and gaming experience to their social status, rank, weapons, and abilities. ",
		definition3: "This creates a never-ending game that can be played in any order with total freedom of movement. This new approach to gaming will allow metaverses to offer high-quality gamification in a persistent world."
	},	
  sliderSection: {
    firstSlideTitle: "PROTÉGEZ L\'ILE,\nET VOS AMIS\nDes mutants",
    firstSlideText: "Allez vérifier si tout le monde va bien, si l\'électricité est toujours allumée et si les protections contre \nles méchants fonctionnent toujours",
    firstStory: "Mutants",
    secondStory: "Monde ouvert",
    thirdStory: "Play to earn",
  },
	mapSection: {
		title: "De nombreux endroits à explorer",
		firstAdvantageTitle: "8 000 000 m²",
		firstAdvantageText: "Carré d'une île",
		secondAdvantageTitle: "1 000 participants",
		secondAdvantageText: "Peuvent jouer sur l\'île en même temps",
		thirdAdvantageTitle: "> 100 000",
		thirdAdvantageText: "Des arbres, des rochers, des gens et de nombreux endroits à visiter",
	},
	charactersSection: {
		title: "Personnalisez votre personnage avec NFT",
		text: "Metaisland est une plate-forme de réseautage social, avec un avatar virtuel, des chats, une amitié, un statut et des goûts. Vous pouvez choisir votre personnage parmi +150 NFT 3D uniques.",
	},
	learnTheStorySection: {
		title: "Apprenez l\'histoire",
		firstStory: "L\'histoire",
		secondStory: "Louez un commerce",
		thirdStory: "Combattre les mutants",
		fourthStory: "Explorez l\'océan",
	},
	nftSection: {
		title: "Unique 2D NFT",
		button: "Allez au NFT shop",
		aquamen: "Aquamen",
		military: "Military",
		punks: "Punks",
		redcards: "Redcards",
		tribes: "Tribes",
		builders: "Builders",
		wolfWood: "Wolf wood",
		wolfIron: "Wolf iron",
		daemons: "Daemons"
	},
	playToEarnSection: {
		title: "Play 2 earn",
		subtitle: "Metaisland est une plate-forme de réseautage social, avec un avatar virtuel, des chats, une amitié, un statut et des goûts. Vous pouvez choisir la peau de votre personnage et créer votre propre personnalité avec des caractéristiques RPG.",
		earn: "Gagnez",
		earnFirstTitle: "Trouvez de la crypto",
		earnFirstText: "Airdrops et mining stones",
		earnSecondTitle: "Gagner des quêtes",
		earnSecondText: "Obtenez des quêtes des PNJ",
		earnThirdTitle: "Louez un commerce",
		earnThirdText: "Des restaurants ou des bureaux de change sont à louer",
		spend: "dépensez",
		spendFirstTitle: "NFT",
		spendFirstText: "Audio, Art, Skins, Objets et Capacités",
		spendSecondTitle: "Acheter de la nourriture",
		spendSecondText: "Vous devez gérer vos ressources",
		spendThirdTitle: "Boire de l\'alcool",
		spendThirdText: "La bière vous aide également à recharger votre énergie",
	},
	soonSection: {
		title: "Bientôt disponible",
		subtitle: "Franchise métaverse originale",
		soonFirstTitle: "Moteur de jeu avancé",
		soonFirstText: "Meta Island traite l\'engagement 3D en temps réel dans un monde ouvert coopératif intrigant, créé avec Unreal Engine 5",
		soonSecondTitle: "Composabilité maximale",
		soonSecondText: "Gagnez du DeFi, jouez à des jeux, discutez avec d\'autres, participez aux DAO et découvrez une économie Web3 avec Holochain pour Genesis Meta Island et ses utilisateurs.",
		soonThirdTitle: "Genesis Island & Franchise",
		soonThirdText: "Élargissez votre horizon Web3 avec la technologie Fintech de premier ordre. Utilisez l\'opportunité prête à l\'emploi de l\'île Genesis ou en franchisez votre propre version!",
	},
	roadmapSection: {
		title: "Roadmap",
		first: "Ventes NFT sur notre place de marché",
		second: "Première démo jouable avec la première île",
		third: "Système de dialogue, aventure, narration",
		fourth: "Système de combat. Développement vertical",
		fifth: "Franchise et développement horizontal, développement commercial",
		button: "Read more about Roadmap",
	},
	blogSection: {
		title: "Blog",
		button: "Lire plus d\'articles",
	},
	teamSection: {
		title: "W.O.K Corp Studio",
		subtitle: "W.O.K Corp studio  is +20 talented people spread around the globe",
		nicolas: {name: "Nicolas Choukroun", profession: "CEO", about: "Ubisoft, Mindscape, Fox, Loriciel, Cryo, EA, Euronext"},
		laurent: {name: "Laurent Cluzel", profession: "Graphics Director", about: "Ubisoft, Sony, Psygnosis, Wizama, EA, Namco"},
		ethan: {name: "Ethan Lewis Maltby", profession: "Director Sound and Music", about: "UEFA, FA, NFL, RBS, Microsoft, Nestle, Cineworld, Telenet, BP, Wella, Walls, Leeds Castle, KCC"},
		iliah: {name: "Ilya Cerberex", profession: "Lead Blockchain Specialist", about: "Python, Php, C++, Full Stack, NEO Chain, Solidity, Hosting"},
		pascale: {name: "Pascale Piel", profession: "CFO", about: "Antabio SAS, Theravectys, Sofrepost, Novexel, Axa"},
		pierre: {name: "Pierre Esteve", profession: "Storywriter Musician", about: "Arte, Movies, TV, Starsync"},
		vitalik: {name: "Vitalik Mraksoll", profession: "Blockchain Developer", about: "C++, React, Python, Solidity, Rust"},
		sanny: {name: "Sanni Muhammed", profession: "Head of Community Manager", about: "Twitter, Reddit, Discord"},
		daniel: {name: "Daniel Morar", profession: "Lead Unreal developer", about: "Blueprint, ALS Character specialist"},
		kriss: {name: "Krzysztof Sandomierski", profession: "Project Manager & Tester", about: "20+ years experience managing teams in the petroleum industry."},		
		abhay: {name: "Abhay Prakashchandra Mahajan", profession: "Backend / WOK-OS Api", about: "PHP/Mysql wizard"},		
		stephane: {name: "Stephane Picq", profession: "Game Music Composer", about: "Atlantis, Dune, Megarace, Captain Blood"},
		beesirin: {name: "Beesirin Impaw", profession: "General Manager Thailand", about: ""},
		sandomierski: {name: "Sandomierski Krzysztof", profession: "Project Manager", about: ""},
	},
	advisorySection: {
		title: "Advisory",
		hassan: {name: "Hassan (Hatu) Sheikh", profession: "Token Economy, Fundraising", about: "Daomaker Co-founder. Founder of ApeTerminal.io"},
		iftikhar: {name: "Iftikhar Qasim", profession: "Senior Advisor SL2 Capital & Hillstone Finance", about: ""},
		serge: {name: "Serge Hascoet", profession: "Chief Creative Officer at Ubisoft for +30 years", about: ""},
		david: {name: "David Atkinson", profession: "Token Economy Advisor", about: "Holochain co-founder"},
		andrey: {name: "Andrey He", profession: "Marketing & Fundraising", about: "NEO chain"},
		babar: {name: "Babar Shabir", profession: "NFT and Blockchain Stratergy", about: ""},
		konstantin: {name: "Konstantin Pechesky", profession: "President of Trinity, investment & crypto expert", about: "Trinity - Venture Capital Club"},				
	},
	partnersSection: {
		title: "Partenaires",
		subtitle: "The Team has published games or worked for these following companies",
		investors: "Seed Investiseurs",
		technologyAndLegalPartners: "Technology and Legal partners",
		wizama: "Magical board game builders since 2017",
		nintendo: "Japanese multinational video game company",
		sega: "Japanese multinational video game and entertainment company",
		ea: "American video game company",
		sony: "Japanese multinational conglomerate corporation",
		mindscape: "French video game developer",
		cryo: "French video game development",
		ubisoft: "French video game company",
	},
	popUpMining: {
		title: "Mining et zone d\'Airdrop",
		text: "Toutes les 2 heures, une alarme retentit, et un airdrop est lancé. Toutes les cryptos non mises en cache par les joueurs après 4 minutes sont automatiquement détruites.",
		more: "Plus"
	},
	popUpFactory: {
		title: "Electric Factory",
		text: "Dans le jeu, l\'ensemble du système électrique est entièrement simulé. Des câbles desservent toutes les zones et il y a même quelques panneaux solaires. Les bobines Tesla envoient l\'énergie à l\'usine électrique où elle est ensuite gérée, stockée et livrée au reste de l\'île. Il existe également des moyens d\'allumer et d\'éteindre l\'électricité dans cette zone.",
		more: "Plus",
	},
	popUpCyber: {
		title: "Cyber area",
		text: "La zone Cyber ​​est l\'un des plus grands bâtiments de l\'île. Environ 500 personnes peuvent y trouver une salle. C\'est un endroit sauvage avec de l\'alcool, de la drogue et des prostituées.",
		more: "Plus",
	},
	popUpSlum: {
		title: "Slum Ville",
		text: "C\'est l\'endroit le moins cher pour obtenir une chambre lorsque vous commencez le jeu. Il est loin de la zone minière / de largage, mais a une ambiance cool. Bien pour commencer le jeu.",
		more: "Plus",
	},
	popUpTech: {
		title: "Tech Ville",
		text: "Tech Ville est la zone administrative. Avec des salles de réunion et la station de téléportation. Il y a aussi des gens bien informés dans la région, où obtenir des informations sur l\'île. La zone comprend un musée, des maisons, la station de téléportation, des restaurants, une place de restauration et un supermarché.",
		more: "Plus",
	},
	popUpLeaw: {
		title: "Leaw Beach",
		text: "Leaw Beach est la première plage à visiter. Nous mettrons à jour le jeu pour en avoir beaucoup plus. C\'est l\'endroit idéal pour se détendre et profiter d\'une discothèque ou d\'un bar.",
		more: "Plus",
	},
	popUpTesla: {
		title: "Tesla Coil zone",
		text: "Ces zones génèrent de l\'énergie pour toutes les structures de l\'île. L\'énergie provient des crypto-mines souterraines. Il y a plusieurs zones Tesla réparties sur l\'île, certaines sont dans la zone sauvage et dangereuses car elles se protègent en détruisant tous les humains qui s\'approchent. La zone Tesla Energy est située à proximité de Techville et les joueurs doivent s\'assurer qu\'elle fonctionne toujours. Il existe des moyens de l\'activer et de le désactiver, et certains joueurs devront acquérir les connaissances nécessaires pour éviter toute coupure de courant de l\'infrastructure qui pourrait mettre la zone protégée en danger d\'être envahie par les créatures de la zone sauvage.",
		more: "Plus",
	},
	popUpTribe: {
		title: "Tribe Village",
		text: "Le village des Tribes a été créé par la femme de Dale lorsqu\'elle s\'est rendu compte qu\'elle résistait au poison. Elle rejoint son mari et sa fille et décide de créer sa propre faction pour venir en aide à ceux qui sont contaminés par la même version de la maladie. Les tribus et les Redcards se sont longtemps entraidés jusqu\'à la mort des deux fondateurs et maintenant, ils sont séparés et se battent parfois ensemble.",
		more: "Plus",
	},
	footer: {
		whitepaper: "Whitepaper",
		pitchdeck: "Pitch Deck Detailed",
		pitchdecklite: "Pitch Deck Lite",
		metaisland: "METAISLAND",
	},
	islToken: {
		title: "ISL token",
		subtitle: "Le jeton (Token) ISL est basé sur ETH est il est le principal de l\'écosystème Metaisland : la couche 1, l\'écosystème Metaverse,\nNFT et le GameFi natif. L\'économie de jetons comprend le staking, les locks, les incitations à la liquidité, les rachats et les burns. Celles-ci sont motivées\npar la demande tirée à la fois par les avantages du gameplay et\nla valeur tirée de l\'économie du métaverse.",
		tokenEconomy: "Economie du Token",
		playIncome: "Revenu de jeu",
		playIncomeFirstTitle: "Souscription mensuelle",
		playIncomeFirstText: "Le Metaverse est gratuit mais pour débloquer toutes les possibilités, les participants doivent payer une redevance mensuelle de 10 $ minimum.",
		playIncomeSecondTitle: "Merchandizing",
		playIncomeSecondText: "Metaisland est une franchise avec des personnages et des histoires, ce qui nous permet de créer toutes sortes de merchandising comme dans toutes les franchises populaires.",
		playIncomeThirdTitle: "Staked Play",
		playIncomeThirdText: "Les joueurs peuvent louer une boutique afin d\'avoir un MPR (Monthly Percentage Revenu). Le Staking % dépend de l\'emplacement de la boutique et de son succès.",
		nftIncome: "Revenus des NFT",
		nftIncomeFirstTitle: "NFT Marketplace",
		nftIncomeFirstText: "Des frais de 2,5 % s\'appliquent à toutes les transactions NFT sur le marché NFT intégré à l\'application.",
		nftIncomeSecondTitle: "Vente de NFT",
		nftIncomeSecondText: "Nos NFT débloquent des fonctionnalités dans le Metaverse, les joueurs devront en collecter plusieurs pour accéder à toutes ces fonctionnalités.",
		nftIncomeThirdTitle: "Location de NFT",
		nftIncomeThirdText: "Les joueurs peuvent louer nos NFT avec leur argent Play to Earn, et également louer les NFT qu\'ils possèdent, pour gagner de l\'argent supplémentaire.",
	},
	tokenEconomy: {
		firstTitle: "Valeur du jeton ISL de l\'économie du jeu",
		gameEconomyFirstTitle: "Buy-backs",
		gameEconomyFirstText: "50% des revenus de jeu sont utilisés pour acheter des jetons sur le marché",
		gameEconomySecondTitle: "Burns",
		gameEconomySecondText: "La gouvernance peut voter pour brûler jusqu\'à 50 % des jetons rachetés.",
		gameEconomyThirdTitle: "Staking Rewards",
		gameEconomyThirdText: "Jusqu\'à 50 % des jetons achetés sur le marché sont distribués aux stakers",
		secondTitle: "Valeur du jeton ISL\n dans l\'écosystème",
		ecosystemFistTitle: "Staking",
		ecosystemFistText: "Le staking de jetons et de jetons  déverrouille certains skins WOK et Avatar. Les avatars sont visibles sur le classement, pour les amis et sur la carte publique.",
		ecosystemSecondTitle: "Discounts",
		ecosystemSecondText: "Une redevance de 2,5 % est perçue sur les échanges de NFT de jeu, c\'est-à-dire les skins. Cette redevance est réduite à 2 % si le joueur mise un montant minimum de jetons de jeu.",
		ecosystemThirdTitle: "Buy-backs",
		ecosystemThirdText: "50% des frais de négociation NFT sont rachetés sur le marché, que le DAO peut voter pour brûler",
		ecosystemFourthTitle: "Staking Yield",
		ecosystemFourthText: "Jusqu\'à 50 % des frais de négociation NFT sont disponibles en tant que récompenses de mise.",
	},
	tokenDistribution: {
		title: "Token Distribution",
		secondTitle: "Total: 100% — 1,000,000,000",
	},
	visionMetaisland: {
		title: "Qu\'est-ce que Métaisland ?",
		subtitle: "Une infrastructure métaverse pour des milliards d\'utilisateurs",
		text: "Metaisland est une infrastructure de blockchain personnalisée - conçue pour le Metaverse. Compatible avec les portefeuilles les plus populaires, relié à toutes les principales chaînes de blocs, et qui combine défi, NFT et jeu pour gagner dans un concept révolutionnaire unique.",
	},
	metaverseBlock: {
		firstTitle: "Une économie métaverse d\'une valeur de 13 000 milliards de dollars d\'ici 2030",
		secondTitle: "Un métaverse pour tous",
		subtitle: "La firme de Wall Street, Morgan Stanley, considère le métaverse comme un marché adressable de 13 000 milliards de dollars qui est susceptible de devenir la \'plate-forme de médias sociaux, de streaming et de jeux de nouvelle génération\'\nLa City est encore plus optimiste et a déclaré: «Nous pensons que le métaverse est la prochaine génération d\'Internet - combinant le monde physique et numérique de manière persistante et immersive - et pas uniquement un monde de réalité virtuelle\', indique le rapport. Un métaverse indépendant des appareils accessible via des PC, des consoles de jeux et des smartphones pourrait créer un très vaste écosystème »",
		date: "(Fortune April 2022)",
		FirstTitle: "Jeton utilitaire Metaisland ISL",
		FirstText: "Le jeton ISL est le carburant qui renforce Metaisland. Les propriétaires d\'îles doivent acheter le jeton ISL sur le marché pour remplir leur réservoir de récompenses. ",
		SecondTitle: "2D NFT Multichain",
		SecondText: "9 collections de 2d PFP NFTs sont disponibles, toutes ayant une utilité. Ils seront disponibles sur plusieurs chaînes et sur de nombreux marchés différents.",
		ThirdTitle: "NFT 3D avec fonctionnalités RPG",
		ThirdText: "Nos NFT 3D sont directement jouables dans le jeu et ont des fonctionnalités de jeu de rôle comme la force, la vitesse, la vie, le mana, la faim, la soif etc...",
		FourthTitle: "Boutique NFT, à acheter ou à louer",
		FourthText: "Nos NFT peuvent être achetés en espèces, en crypto ou même loués ! Pas besoin d\'être riche pour profiter de Metaisland.",
		FifthTitle: "WOK-OS pour gérer votre ID",
		FifthText: "Le WOK-OS est notre backend pour gérer l\'identité des utilisateurs, il garantit la confidentialité de chaque participant Metaverse. ",
		SixthTitle: "Metaisland Franchise",
		SixthText: "Les meilleurs utilisateurs de Metaverse reçoivent l\'accès pour gérer leur propre île et gagner leur vie en gérant leur propre Metaisland.",
		firstAdvantageTitle: "Abonnements en espèces",
		firstAdvantageText: "Une île avec 1000 joueurs génère 20 000,00 USD par mois de ventes",
		secondAdvantageTitle: "Abonnements avec crypto",
		secondAdvantageText: "1 million de joueurs généreraient des millions USDT de ventes par mois",
		thirdAdvantageTitle: "Ventes NFT",
		thirdAdvantageText: "Bien que les NFT ne soient pas obligatoires pour jouer, leurs attributs uniques les rendent très attrayants.",
		fourthAdvantageTitle: "NFT royalties",
		fourthAdvantageText: "10 % pour chaque revente peut générer une énorme source de revenus au fil du temps.",
		fifthAdvantageTitle: "Franchising",
		fifthAdvantageText: "La franchise nous permet de devenir fournisseur de contenu et de vendre des clés API sans avoir à localiser dans tous les pays.",
		sixthAdvantageTitle: "Merchandising",
		sixthAdvantageText: "T-shorts, tasses, souvenirs et autres articles liés au jeu en vente dans la boutique en ligne.",
	},
	visionSlider: {
		title: "Metaisland offre un métaverse pour tout le monde.",
		subtitle: "Metaisland est une plate-forme de réseautage social, avec un avatar virtuel, des chats, une amitié, un statut et des goûts. Vous pouvez choisir un personnage parmi plus de 150 personnages 3D uniques et créer votre propre personnalité en utilisant des caractéristiques de type RPG.",
	},
	booksPlay: {
		title: "Metaisland",
		text: "A UNIQUE série de livres",
	},
	books: {
		title: "choose your book",
		firstTitle: "Red Cards",
		firstDescription: "Description",
		firstText: "De Nicolas Choukroun, Nick Brown",
		secondTitle: "Tribes",
		secondDescription: "Description",
		secondText: "De Nicolas Choukroun, Nick Brown",
		thirdTitle: "Aquamen",
		thirdDescription: "Description",
		thirdText: "De Nicolas Choukroun, Nick Brown",
		fourthTitle: "Military",
		fourthDescription: "Description",
		fourthText: "De Nicolas Choukroun, Nick Brown",
		fifthTitle: "Punks",
		fifthDescription: "Description",
		fifthText: "De Nicolas Choukroun, Nick Brown",
		sixthTitle: "Daemons",
		sixthDescription: "Description",
		sixthText: "De Nicolas Choukroun, Nick Brown",
		seventhTitle: "Builders",
		seventhDescription: "Description",
		seventhText: "De Nicolas Choukroun, Nick Brown",
		eightsTitle: "Wolf wood",
		eighthDescription: "Description",
		eightsText: "De Nicolas Choukroun, Nick Brown",
		ninthTitle: "Wolf iron",
		ninthDescription: "Description",
		ninthText: "De Nicolas Choukroun, Nick Brown",
	},
	stakingSlider: {
		title: "STAKING c\'est en fait louer un magasin",
		subtitle: "Vous pouvez posséder ou louer nos NFT 3D. Chaque série a ses caractéristiques spécifiques inspirées des jeux de rôle (RPG). Ils sont jouables dans le jeu.",
		secondTitle: "Cliquez pour louer votre propre boutique.",
		button: "Aller à la location",
	},
	stakingMechanism: {
		title: "Metaisland",
		subtitle: "DISPOSE D\'UN MÉCANISME DE STAKING UNIQUE",
		button: "Aller au Staking",
		firstStep: "LOUER UNE BOUTIQUE A METAISLAND",
		secondStep: "CHAQUE MAGASIN A SON MPR (Taux mensuel en pourcentage)",
		thirdStep: "CHAQUE MOIS ÊTRE PAYÉ % MPR DU PRIX DE LOCATION",
	},
	nftMechanism: {
		title: "Metaisland",
		subtitle: "Possède des fonctionnalités NFT UNIQUES",
		button: "Aller à la boutique NFT",
		firstStep: "Aller à la boutique NFT",
		secondStep: "Vous pouvez posséder ou louer des NFT, payer en espèces, wallet",
		thirdStep: "Vous pouvez maintenant utiliser votre NFT dans le Metaverse.",
	},
	pageNotFound: {
		title: "404 - Page Non Trouvée",
		subtitle: "Vous avez oublié quelque chose?",
		button: "Aller a la Page Principale",
	},
	sinceTimer: {
		title: "Nous developpons Metaisland depuis",
		subtitle: "Suivez notre communauté.",
		second: "second",
		minute: "minute",
		hour: "hour",
		day: "day",
		month: "month",
		year: "year",
		seconds: "seconds",
		minutes: "minutes",
		hours: "hours",
		days: "days",
		months: "months",
		years: "years",
	},
	subscribeEmail: {
		title: "Survivor, you did it!",
		alreadySub: "Already joined!",
		subtitle: "Welcome to Metaisland! \nYou have taken the first step in escaping the brutal reality and boredom of reality!\n At Metaisland, we are creating a future virtual reality, with an economy a social system and plenty of ways to entertain yourself in a toxic free environment. A decentralized place where freedom of speech is respected and where you can enjoy meeting friends and have create a different personality, do things extraordinary and live in an intriguing Island full of mystery. \nYou can enjoy a beer and relax, talk with friends, or go after the Mutants in the Wild Zone and have a lot of action, fights and explosions. You can also monetize the time spent in the game with all kind of DEFI possibilities that no other entertainment product is proposing. \nMetaverses are the FUTURE! And no need to wait long time to participate, you can join today this revolution. \nAs a token of appreciation for joining us on our journey of building Metaisland, we are delighted to offer you an opportunity to win a free NFT \nThis NFT is a FOUNDER NFT and will have the unique possibility to enter any Metaisland without asking for citizenship, as a VIP member of the project. Don\’t miss this opportunity!",
		toRedeem: "To redeem this Free NFT, follow these steps.",
		discord: "Discord",
		joinDiscord: "Join our Discord server:",
		crew: "Crew3",
		joinCrew: "Go to our Crew 3 page and complete the tasks:",
		bottomText: "Check out our weekly podcast series, Meta Talks, for all the latest info on Metaverse. Nikko Founder and CEO from Metaisland has got you covered, so be sure to join in and learn more about Crypto, GameFi, and the Metaverse itself. \nLet\’s build Metalsland together!",
		talkWith: "Meta Talk With Nikko CEO MetaIsland - WIN FREE NFT - YouTube",
		button: "Go to the Main Page",
	},
	box: {
		title: "Cliquez pour générer NFT MAGIC BOX!",
		button: "Générer",
		nftTitle: "Offre exclusive pour les premiers utilisateurs.",
		nftSubtitle: "Vous recevrez 3 NFT pour le prix d\'un ! Pour le prix de 0,1 ETH seulement.",
		firstNftTitle: "Daemons#41",
		firstNftText: "NFT 2D Avatars",
		secondNftTitle: "Charlie#08",
		secondNftText: "NFT 2D Avatars",
		thirdNftTitle: "Wolf wood#175",
		thirdNftText: "NFT 2D Avatars",
	},
	browseNft: {
		title: "METAISLAND 3D NFT",
		subtitle: "Vous pouvez posséder ou louer des NFT 3D. Chaque série a ses caractéristiques spécifiques inspirées des jeux de rôle (RPG). Ils sont jouables dans le jeu et ressemblent exactement à ceci.",
		secondTitle: "Cliquez pour PARCOURIR NFT",
		button: "Parcourir",
		firstNftTitle: "John#04",
		firstNftText: "NFT 3D Avatars",
		secondNftTitle: "Ariana#05",
		secondNftText: "NFT 3D Avatars",
		thirdNftTitle: "Assassin#01",
		thirdNftText: "NFT 3D Avatars",
	},
	mysterySlider: {
		title: "METAISLAND 2D NFT",
		subtitle: "Nos profils 2D NFT ont tous des caractéristiques spécifiques, comme donner la possibilité de nager ou d\'être insensible au poison. Nous avons 7 factions, chacune avec une histoire et une utilité dans le jeu. Les joueurs peuvent avoir un portefeuille NFT qu\'ils peuvent utiliser dans le métaverse pour ajouter plus de capacités à leur personnage 3D",
		secondTitle: "Cliquez pour plus d\'informations sur les factions.",
		button: "Book of Knowledge",
		secondButton: "Parcourir NFT WOLF PASSES",
		wolfTitle: "METAISLAND WOLF PASS",
		wolfText: "utilisés pour donner accès à un endroit secret et caché de l\'île. Ils servent également de droit de vote pour notre D.A.O et d\'accès aux bêtas privées. Enfin, ils sont cool et créatifs, et sont parfaits pour être utilisés comme photo de profil sur votre réseau social préféré. Tous nos NFT ont 2 versions, une propre sans texte et une version améliorée avec du texte.",
		nftTitle: "WOLF PASS",
		nftText: "Metaisland",
	},
}

export default fr
