import style from "styles/footer.module.css";
import React, { memo } from "react";
import LinksBlock from "components/linksBlock";
import Link from 'next/link'
import useLocale from '../../hooks/useLocale'

const Footer = () => {
    const { footer } = useLocale()

    return (
        <div className={style.footer}>
            <div className={style.container}>
                <div className={style.content}>
                    <div className={style.logo}>{footer.metaisland}</div>
                    <div className={style.linksBlock}>
                        <Link href="https://whitepaper.metaisland.gg">
                            <a target="_blank">
                                <p className='md:pb-0 pb-6'>{footer.whitepaper}</p>
                            </a>
                        </Link>
                        <Link href="https://metaisland.gg/Pitch Deck Metaisland Lite.pdf">
                            <a target="_blank">
                                <p className='md:pb-0 pb-6'>{footer.pitchdecklite}</p>
                            </a>
                        </Link>

                        <p className='md:pb-0 pb-6'>|</p>
                        
                        <Link href="https://metaisland.gg/Pitch Deck - Metaisland Detailed.pdf">
                            <a target="_blank">
                                <p className='md:pb-0 pb-6'>{footer.pitchdeck}</p>
                            </a>
                        </Link>
                        <LinksBlock className={style.links} isFooter={true} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(Footer);
