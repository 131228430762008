import en from "./en"
import fr from "./fr"
import sp from './sp'
import ru from './ru'
import ch from './ch'
import hi from './hi'
import { ILocale } from "./type"

export default {
  en,
  fr,
  sp,
  ru,
  ch,
  hi
}

export const localesMap = new Map<string, ILocale>([
  ["en", en],
  ["fr", fr],
  ["sp", sp],
  ["ru", ru],
  ["ch", ch],
  ["hi", hi],
])
