import { localesMap } from "locales"
import en from "locales/en"
import { ILocale } from "locales/type"
import {useContext, useMemo} from "react"
import LanguageContext from '../context/languageContext'

export default function useLocale(): ILocale {
  const {activeLang} = useContext(LanguageContext)

  const dictionary: ILocale = useMemo(() => {
    if (!activeLang) return en

    const _locale = localesMap.get(activeLang)

    if (!_locale) return en

    return _locale
  }, [activeLang])

  return dictionary
}
