import Link from "next/link"
import style from "styles/header.module.css"
import React, { memo, useContext, useEffect, useState } from "react"
import logo from "public/image/mainLogo.svg"
import logoWithoutOpacity from "public/image/mainLogoWithoutOpacity.svg"
import logoMobile from 'public/image/logoMobile.svg'
import burger from "public/image/burger.svg"
import close from "public/image/btnClose.svg"
import useLocale from "hooks/useLocale"
import DropDown from '../dropDown'
import LanguageContext from '../../context/languageContext'
import { useRouter } from "next/router"
import Snowfall from 'react-snowfall'
import Head from "next/head"
import Script from 'next/script'

let scrollToElement = require('scroll-to-element')

const Header = ({title='Metaisland'}: {title?: string}) => {
	const router = useRouter()
	const [open, setOpen] = useState(false)
	const { activeLang } = useContext(LanguageContext)
	const [isOpacityHeader, setIsOpacityHeader] = useState(false)

	const { header } = useLocale()

	const menuHandler = () => {
		setOpen(!open)
	}

	useEffect(() => {
		if (open) {
			document.body.style.overflow = "hidden"
		} else {
			document.body.style.overflow = ""
		}
	}, [open])

	useEffect(() => {
		const heightFirstScreen = window.innerHeight - window.innerHeight * 0.6
		function onScrolling() {
			//@ts-ignore
			if (heightFirstScreen < window.scrollY) {
				setIsOpacityHeader(true)
			} else setIsOpacityHeader(false)
		}
		onScrolling()
		window.addEventListener("scroll", onScrolling)
		return () => window.removeEventListener("scroll", onScrolling)
	}, [])

	return (
		<header className={`${style.header} ${isOpacityHeader ? 'bg-header' : 'lg:bg-header-40 bg-header'} `}>
			<Head>
				<link
					rel="prefetch"
					href="/fonts/Coalition.ttf"
					as="font"
					crossOrigin='anonymous'
					type="font/ttf"
				/>
				<link
					rel="prefetch"
					href="/fonts/Industry.ttf"
					as="font"
					crossOrigin='anonymous'
					type="font/ttf"
				/>

				<link rel="apple-touch-icon" sizes="180x180" href="apple-icon-180x180.png" />
				<link rel="apple-touch-icon" sizes="152x152" href="apple-icon-152x152.png" />
				<link rel="apple-touch-icon" sizes="144x144" href="apple-icon-144x144.png" />
				<link rel="apple-touch-icon" sizes="120x120" href="apple-icon-120x120.png" />
				<link rel="apple-touch-icon" sizes="114x114" href="apple-icon-114x114.png" />
				<link rel="apple-touch-icon" sizes="76x76" href="apple-icon-76x76.png" />
				<link rel="apple-touch-icon" sizes="72x72" href="apple-icon-72x72.png" />
				<link rel="apple-touch-icon" sizes="60x60" href="apple-icon-60x60.png" />
				<link rel="apple-touch-icon" sizes="57x57" href="apple-icon-57x57.png" />

				<link rel="icon" type="image/png" sizes="192x192" href="android-icon-192x192.png" />

				<link rel="icon" type="image/png" sizes="96x96" href="favicon-96x96.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="favicon-16x16.png" />

				<link rel="manifest" href="manifest.json" />
				<meta name="apple-mobile-web-app-status-bar-style" content="black" />
				<meta name="msapplication-TileColor" content="#ffffff" />
				<meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
				<meta name="theme-color" content="#000000" />

				<title>{title}: The Crypto Metaverse</title>
				<meta name="description" content="Metaisland is an ambitious project aiming to create a disruptive metaverse supported by a real crypto economy, offering a unique social system and entertainment experience. The vision is to surpass traditional media like Television and the Internet. The primary goal is to address the issue of cryptocurrency's dependency on the real economy and create a stable coin that remains unaffected by macroeconomic factors." />
				<meta property="og:description" content="Open world metaverse: The Crypto MNetaverse" />
				<meta name="keywords" content="Crypto, multiplayer metaverse, NFT, metaisland" />

				<meta property="og:site_name" content="Metaisland, Meta Island" />
				<meta property="og:url" content="https://metaisland.gg" />
				<meta property="og:title" content={title} />
				<meta property="og:image" content="https://metaisland.gg/metaimage.jpg" />
				<meta property="og:description" content="Metaisland is an ambitious project aiming to create a disruptive metaverse supported by a real crypto economy, offering a unique social system and entertainment experience. The vision is to surpass traditional media like Television and the Internet. The primary goal is to address the issue of cryptocurrency's dependency on the real economy and create a stable coin that remains unaffected by macroeconomic factors." />
				<meta property="og:type" content="website" />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />

				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site_name" content="Metaisland" />
				<meta name="twitter:site" content="@metaisland" />
				<meta name="twitter:creator" content="The Wolf" />
				<meta name="twitter:url" content="https://metaisland.gg" />
				<meta name="twitter:title" content={title} />
				<meta name="twitter:image" content="https://metaisland.gg/metaimage.jpg" />
				<meta name="twitter:description" content="OMetaisland is an ambitious project aiming to create a disruptive metaverse supported by a real crypto economy, offering a unique social system and entertainment experience. The vision is to surpass traditional media like Television and the Internet. The primary goal is to address the issue of cryptocurrency's dependency on the real economy and create a stable coin that remains unaffected by macroeconomic factors." />
				<meta name="copyright" content="Copyright © 2021, 2022, 2023, 2024. All Rights Reserved." />
				<meta name="author" content="The Wolf" />
				<meta name="generator" content="Brain" />

			</Head>

			<Script strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_CONFIG}`} />
			<Script strategy="lazyOnload">
				{`
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_CONFIG}', {
					page_path: window.location.pathname,
					});
				`}
			</Script>

			<div className={style.container}>
				<div className={style.mobile}>
					<Link href="/" locale={activeLang}>
						<a>
							<img
								src={logoMobile.src}
								alt="Sanguage Selection"
							/>
						</a>
					</Link>
					<div className={style.buttons}>
						<button
							onClick={() => window.open('https://wok-os.com/login.php', '_blank')}
							className={style.header__navMenu__blockLinksRight__btn_lightGreen}>{header.enterWokOS}
						</button>
						<button
							onClick={() => window.open('https://nft.metaisland.gg', '_blank')}
							className={style.header__navMenu__blockLinksRight__btn_lightGreen}>{header.enterNFT}
						</button>
							<div onClick={menuHandler} style={{ minWidth: open ? 32 : 48, minHeight: open ? 32 : 48 }} className={style.button}>
							<img
								src={open ? close.src : burger.src}
								alt=""
								aria-label="burger"
							/>
						</div>
					</div>
				</div>
				<nav className={`${style.header__navMenu} ${open && style.header__open}`}>
					<div className={style.header__navMenu__blockLinksLeft}>
						<Link href="/vision" locale={activeLang}>
							<a
								onClick={() => {
									if (open) setOpen(false)
								}}
								className={`${style.header__navMenu__blockLinksRight__linkItem} ${router.pathname === "/vision" ? style.active : ""}`}>{header.vision}</a>
						</Link>
						<Link href="/mystery" locale={activeLang}>
							<a
								onClick={() => {
									if (open) setOpen(false)
								}}
								className={`${style.header__navMenu__blockLinksRight__linkItem} ${router.pathname === "/mystery" ? style.active : ""}`}>{header.nft}</a>
						</Link>
						<Link href="/token" locale={activeLang}>
							<a
								onClick={() => {
									if (open) setOpen(false)
								}}
								className={`${style.header__navMenu__blockLinksRight__linkItem} ${router.pathname === "/token" ? style.active : ""}`}>{header.token}
							</a>
						</Link>

						<Link href="/#franshise" locale={activeLang}>
							<a
								onClick={() => {
									scrollToElement(document.getElementById('franshise'), {
										offset: -100,
										duration: 1000
									})
									if (open) setOpen(false)
								}}
								className={`${style.header__navMenu__blockLinksRight__linkItem} ${router.asPath === "/#franshise" ? style.active : ""}`}
							>{header.franshise}</a>
						</Link>
						
						<Link href="/staking" locale={activeLang}>
							<a
								onClick={() => {
									if (open) setOpen(false)
								}}
								className={`${style.header__navMenu__blockLinksRight__linkItem} ${router.pathname === "/staking" ? style.active : ""}`}>{header.staking}
							</a>
						</Link>

						<Link href="/#roadmap" locale={activeLang}>
							<a
								onClick={() => {
									scrollToElement(document.getElementById('roadmap'), {
										offset: -100,
										duration: 1000
									})
									if (open) setOpen(false)
								}}
								className={`${style.header__navMenu__blockLinksRight__linkItem} ${router.asPath === "/#roadmap" ? style.active : ""}`}
							>{header.roadmap}</a>
						</Link>


						<Link href="/#blog" locale={activeLang}>
							<a
								onClick={() => {
									scrollToElement(document.getElementById('blog'), {
										offset: -100,
										duration: 1000
									})
									if (open) setOpen(false)
								}}
								className={`${style.header__navMenu__blockLinksRight__linkItem} ${router.asPath === "/#blog" ? style.active : ""}`}>{header.blog}</a>
						</Link>

					</div>
					<div className={style.header__navMenu__blockLogo}>
						<Link href="/" locale={activeLang}>
							<a>
								<img
									src={isOpacityHeader ? logoWithoutOpacity.src : logo.src}
									// src={newLogo.src}
									alt=""
									aria-label="meta island"
								/>
							</a>
						</Link>
					</div>
					<div className={style.header__navMenu__blockLinksRight}>
						<Link href="/book" locale={activeLang}>
							<a
								onClick={() => {
									if (open) setOpen(false)
								}}
								className={`${style.header__navMenu__blockLinksRight__linkItem} ${router.pathname === "/book" ? style.active : ""}`}>{header.bookOfKnowlage}</a>
						</Link>

						<button
							onClick={() => window.open('https://wok-os.com/login.php', '_blank')}
							className={`${style.header__navMenu__blockLinksRight__btn_lightGreen} ${open && style.hide}`}>{header.enterWokOS}
						</button>
						|
						<button
							onClick={() => window.open('https://nft.metaisland.gg', '_blank')}
							className={`${style.header__navMenu__blockLinksRight__btn_lightGreen} ${open && style.hide}`}>{header.enterNFT}
						</button>
						<DropDown />
					</div>
				</nav>
			</div>
			{/* <Snowfall
				style={{
					position: 'fixed',
					width: '100vw',
					height: '100vh'
				}}
				snowflakeCount={50}
				speed={[0.5, 3]}
				wind={[0, 1]}
				radius={[0.5, 4]}
			/>	 */}
		</header>
	)
}

export default memo(Header)
